import { callApi } from "@/composables/api-client";
import AutocompleteFilter from "@/models/base/AutocompleteFilter";
import AutocompleteListItem from "@/models/base/AutocompleteListItem";
import AutocompletePaginatedResponse from "@/models/base/AutocompletePaginatedResponse";
import JobData from "@/models/job/JobData";
import { useRootStore } from "@/stores/Root";

/**
 * Возвращает данные для табличного отчета
 * @param {string} url
 * @param {number} page
 * @param {number} pageSize
 * @param {array} selectedCodes
 * @param {Filter} filter
 * @param {string} model
 * @returns {Promise<AutocompletePaginatedResponse>}
 */
export function loadSearchData<Filter extends AutocompleteFilter>(
  url: string,
  page: number,
  pageSize: number,
  selectedCodes: number[] | string[],
  filter: Filter,
  model?: string
): Promise<AutocompletePaginatedResponse> {
  return new Promise((resolve) => {
    const config = {
      page: page,
      page_size: pageSize,
      search: filter.search,
      exclude_values: selectedCodes,
      // selected_сodes: selectedCodes,
    };
    callApi<AutocompletePaginatedResponse>(url, {
      model: typeof model != "undefined" ? model : "",
      config,
    })
      .then((apiResponse) => {
        const response = apiResponse;
        resolve({
          items: response.items as AutocompleteListItem[],
          has_next_page: response.has_next_page,
        } as AutocompletePaginatedResponse);
      })
      .catch(() => {
        resolve({
          items: [] as AutocompleteListItem[],
          has_next_page: false,
        } as AutocompletePaginatedResponse);
      });
  });
}
/**
 * Совершает поиск по введенной строке
 * @param {string} model
 * @param {string} term
 * @param {number} page
 * @returns {Promise<string | null>}
 */
export async function search<ResponseType>(
  model: string,
  term: string,
  page: number
) {
  return await callApi<ResponseType>("site/search", {
    model,
    term,
    page,
  });
}
/**
 * Запрашивает возможные варианты для автодополнения
 * @param {string} model
 * @param {string} term
 * @returns {Promise<string | null>}
 */
export async function loadSuggestAutocomplete<ResponseType>(
  model: string,
  term: string
) {
  return await callApi<ResponseType>("site/suggest_autocomplete", {
    model,
    term,
  });
}
/**
 * Запрашивает возможные варианты автодополнения для q-select
 * @param {string} url
 * @param {object} payload
 * @returns {Promise}
 */
export async function loadQSelectAutocomplete<ResponseType>(
  url: string,
  payload: {
    term: string;
    [key: string]: string | number;
  }
) {
  return await callApi<ResponseType>(url, payload);
}

/**
 * Тип данных для ответа от пульса
 */
type PulseData = {
  code: number | string;
  jobs: Array<JobData>;
};
/**
 * Отправляет запрос на пульс
 * @return {Promise}
 */
export function loadPulse(): Promise<PulseData> {
  return new Promise((resolve) => {
    callApi<PulseData>("/pulse")
      .then((response) => {
        resolve(response);
      })
      .catch((response) => {
        resolve(response);
      });
  });
}
/**
 * Помечает задачу как прочитанную
 * @param {number} jobId
 * @return {Promise}
 */
export function markJobAsSeen(jobId: number | string): Promise<void> {
  return new Promise((resolve) => {
    useRootStore().showPreloader();
    callApi<void>("mark_job_as_seen", { job_id: jobId })
      .then(() => resolve())
      .finally(() => {
        useRootStore().hidePreloader();
      });
  });
}
/**
 * Отменяет задачу
 * @param {number} jobId
 * @return {Promise}
 */
export function markJobAsCancelled(jobId: number | string): Promise<void> {
  return new Promise((resolve) => {
    useRootStore().showPreloader();
    callApi<void>("mark_job_as_cancelled", { job_id: jobId })
      .then(() => resolve())
      .finally(() => {
        useRootStore().hidePreloader();
      });
  });
}
