
import { defineComponent } from "vue";
import { useUserStore } from "@/stores/User";
import UserState from "@/models/stores/UserState";
import { getBaseUrl } from "@/composables/url";
export default defineComponent({
  computed: {
    userStore(): UserState {
      return useUserStore();
    },
    username(): string {
      return this.userStore.name || "Гость";
    },
    avatar(): string {
      return (
        this.userStore.avatar ||
        getBaseUrl() + "/template/img/avatars/kitfort-user-no-image.png"
      );
    },
  },
});
