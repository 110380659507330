
import { useRootStore } from "@/stores/Root";
import { PropType, defineComponent } from "vue";
import AppStatus from "./AppStatus.vue";

export default defineComponent({
  props: {
    isNarrow: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      resizeObserver: null as null | ResizeObserver,
    };
  },
  /**
   * Обработчик после добавления элемента в DOM
   */
  mounted() {
    useRootStore().setIsNarrowPage(this.isNarrow);
    // Обработчик изменения размеров страницы
    this.resizeObserver = new ResizeObserver((entries) => {
      if (entries.length > 0) {
        useRootStore().refreshAppWidth();
        if (this.$slots.footer) {
          const appStatusContainer = this.$refs.app_status.$el as HTMLElement;
          useRootStore().footerBlockHeight =
            appStatusContainer.getBoundingClientRect().height;
        } else {
          useRootStore().footerBlockHeight = 0;
        }
      }
    });
    this.resizeObserver.observe(
      document.querySelector(".page-wrapper") as Element
    );
  },
  unmounted() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  computed: {
    wrapperClass(): string {
      const classes = ["page-wrapper"];
      if (this.isNarrow) {
        classes.push("page-wrapper--narrow");
      }
      return classes.join(" ");
    },
    /**
     * Открыта шторка с поиском?
     * @returns boolean
     */
    isSearchOpen(): boolean {
      return useRootStore().isSearchOpen;
    },
  },
  components: { AppStatus },
});
