import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppNavbar = _resolveComponent("AppNavbar")!
  const _component_AppSearchResults = _resolveComponent("AppSearchResults")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AppPreloader = _resolveComponent("AppPreloader")!
  const _component_AppPopover = _resolveComponent("AppPopover")!
  const _component_AppModal = _resolveComponent("AppModal")!
  const _component_AppUserJobs = _resolveComponent("AppUserJobs")!

  return (_ctx.isLoadedUser)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.wrapperClass),
        style: _normalizeStyle(_ctx.wrapperCssStyle),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clearPopover && _ctx.clearPopover(...args)))
      }, [
        _createVNode(_component_AppNavbar, { class: "navigation" }),
        _createVNode(_Transition, { name: "fssr" }, {
          default: _withCtx(() => [
            (_ctx.isSearchOpen)
              ? (_openBlock(), _createBlock(_component_AppSearchResults, { key: 0 }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _withDirectives((_openBlock(), _createBlock(_component_router_view, {
          key: _ctx.$route.fullPath
        })), [
          [_vShow, !_ctx.isSearchOpen]
        ]),
        (_ctx.isPreloaderVisible)
          ? (_openBlock(), _createBlock(_Transition, {
              key: 0,
              name: "preloader"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_AppPreloader)
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.popover)
          ? (_openBlock(), _createBlock(_Transition, {
              key: 1,
              name: "popover"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AppPopover, { ref: "popover" }, null, 512)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.modal)
          ? (_openBlock(), _createBlock(_Transition, {
              key: 2,
              name: "modal"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AppModal)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.jobs)
          ? (_openBlock(), _createBlock(_Transition, {
              key: 3,
              name: "jobs"
            }, {
              default: _withCtx(() => [
                (_ctx.jobs.length > 0)
                  ? (_openBlock(), _createBlock(_component_AppUserJobs, {
                      key: 0,
                      jobs: _ctx.jobs
                    }, null, 8, ["jobs"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ], 6))
    : _createCommentVNode("", true)
}