import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-04070a9a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "content",
  ref: "list"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "autocomplete-results",
    style: _normalizeStyle(_ctx.autocompleteCssVar)
  }, [
    (_ctx.autocompleteData.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.autocompleteData, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: 'autocomplete-key-' + index,
              onClick: ($event: any) => (_ctx.clickBtn(item)),
              class: _normalizeClass([
          'autocomplete-item',
          _ctx.autocompleteItemClasses(index + 1),
          _ctx.autocompleteTagItemClass(item),
        ])
            }, [
              _createElementVNode("span", {
                innerHTML: _ctx.getPreparedValue(item.label)
              }, null, 8, _hoisted_3)
            ], 10, _hoisted_2))
          }), 128))
        ], 512))
      : _createCommentVNode("", true)
  ], 4))
}