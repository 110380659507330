import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f552f29c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile" }
const _hoisted_2 = { class: "username" }
const _hoisted_3 = { class: "avatar" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.username), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("img", { src: _ctx.avatar }, null, 8, _hoisted_4)
    ])
  ]))
}