const makeStyle = (style: string, textColor: string) => {
  return {
    textColor,
    color: style,
  };
};
export const styles = {
  info: makeStyle("pmc-alert-info", "pmc-button-default"),
  primary: makeStyle("pmc-button-background-brand", "pmc-button-brand"),
  error: makeStyle("pmc-alert-danger", "pmc-button-danger"),
  success: makeStyle("pmc-extra-review-good", "pmc-button-success"),
  warning: makeStyle("pmc-alert-warning", "pmc-extra-reviews"),
};
export type NotifyStyles = keyof typeof styles;
